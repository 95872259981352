@media print {
    @page {
        size: portrait A4;
        margin: 0mm !important;
    }
    
    * {
        print-color-adjust: exact !important;
    }
    
    .noprint {
        display: none !important;
    }

    .nobreak, .documents-content-card, .p-accordion-tab {
        page-break-inside: avoid;
    }

    .break-always {
        page-break-before: always;
    }

    .break-spacer {
        width: 100%;
        height: 2rem;
    }

    .vertical-timeline {
        padding: 3.3rem 0 !important;

        &-element {
            margin: 1rem 0 !important;

            &:first-child {
                margin-top: 0 !important;
            }

            &:last-child {
                margin-bottom: 0 !important;
            }

            &-date {
                padding-bottom: 0 !important;
                font-size: .85rem !important;
            }
            &-content p {
                font-size: 1rem !important;
            }
        }
    }

    .p-accordion-tab {
        &:not(:first-child) {
            margin-top: 1rem !important;
        }

        &:has(div.accordion-break) {
            page-break-before: always;
            padding-top: 2rem !important;
        }
    }

    .p-accordion-header, .p-accordion-header-text {
        pointer-events: none !important;

        & a[href]:after {
            content: none !important;
        }
    }

    .skill {
        width: 100% !important;
    }

    .skill-headline, .documents-headline, .experience-headline {
        padding-top: 10px;
        margin-bottom: .75rem !important;
    }

    .p-card, .p-card-body {
        page-break-inside: avoid !important;
    }
}

@media screen {
    .print-only {
        display: none;
    }
}

body {
    min-width: 100%;
    min-height: 100%;
    margin: 0 0 75px 0;
    background: var(--surface-ground);
    font-family: var(--font-family);
    color: var(--text-color);
}

.background {
    position: fixed;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: var(--surface-ground);
}

a {
    text-decoration: none;
    color: var(--primary-color);

    &:hover {
        color: var(--primary-400);
    }
}

.pi-star-fill {
    color: var(--primary-color) !important;
}

.p-accordion-tab {
    margin-bottom: 4px !important;

    &-active {
        & .p-accordion-header a {
            border-top-left-radius: var(--border-radius) !important;
            border-top-right-radius: var(--border-radius) !important;
        }

        .p-accordion-content {
            border-bottom-left-radius: var(--border-radius) !important;
            border-bottom-right-radius: var(--border-radius) !important;
        }
    }
}

.p-accordion-header-link {
    padding: 1.25rem !important;
}

.timelineIcon {
    box-shadow: none !important;
}

@media only screen and (min-width: 1170px) {
    .timelineIcon > svg {
        height: 32px !important;
        width: 32px !important;
        margin-top: -16px !important;
        margin-left: -16px !important;
    }
}

@media only screen and (max-width: 1170px) {
    .timelineIcon > svg {
        height: 24px !important;
        width: 24px !important;
        margin-top: -12px !important;
        margin-left: -12px !important;
    }
}

.topbar {
    font-size: 1.6rem;
    position: absolute;
    top: .7rem;
    right: 2.5rem;
    z-index: 10;

    & > div {
        display: inline-block;

        & > div {
            display: flex;
            justify-content: space-between;
            gap: .5rem;
            flex-wrap: wrap;
            flex: 0 0 auto;
            align-items: center;
        }
    }

    &-item {
        padding: .5rem;
        cursor: pointer;

        &:hover {
            color: var(--primary-color);
        }
    }
}

.overview {
    display: block !important;
    margin: 135px auto;
    width: 90%;
    max-width: 1170px;

    &-image {
        margin-top: 55px;
        text-align: center;
    }

    &-image img {
        width: 65%;
        border-radius: 100%;
        object-fit: cover;
        max-width: 380px;
        max-height: 380px;
    }

    &-description {
        padding-left: 50px;

        & h1 {
            font-size: 2.5em;
            margin-bottom: 10px;
        }
    }

    &-tag { 
        font-size: 0.9em !important;
        margin: 5px;

        & .p-tag-icon {
            font-size: 0.9em !important;
        }
    }

    &-keyfacts {
        margin-top: 30px;
        line-height: 1.6em;

        & li span {
            vertical-align: text-top;
        }
        & li svg {
            vertical-align: middle;
        }
    }

    @media only screen and (min-width: 1170px) {
        &-image, &-description {
            width: 50%;
            float: left;
        }
    }

    &:after {
        content: " ";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0;
    }
}

.skill {

    display: block !important;
    margin: 150px auto 0;
    width: 90%;
    max-width: 1170px;

    &-headline {
        width: 100%;
        text-align: center;
        font-size: 1.6em;
        margin-bottom: 75px;
    }

    @media only screen and (min-width: 1170px) {
        &-cloud, &-description {
            width: 50%;
            float: left;
        }
    }

    &-cloud canvas {
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    &-description {

        padding-left: 75px;

        & h3 {
            font-size: 1.6em;
        }

        &-rating {
            margin: 30px 0;
            
            & span {
                vertical-align: middle;
            }

            & > div {
                display: inline-block;
                margin-left: 20px;
            }

            & .p-rating-icon:hover {
                &.pi-star-fill {
                    color: var(--primary-color) !important;
                }

                &.pi-star {
                    color: var(--text-color) !important;
                }
            }
        }
    }

    &-content {
        display: flex !important;
        flex-wrap: wrap;
        justify-content: center;
        margin: auto;
        width: 90%;
        max-width: 1170px;
    }

    &-card {
        max-width: 100%;
        width: 320px;
        margin: 10px;
        background-color: var(--surface-d) !important;
        page-break-inside: avoid;
    
        & .p-card-title {
            font-size: 1.35rem;
        }

        & .p-card-content {
            padding: 0 !important;
        }

        &-rating {            
            & span {
                vertical-align: middle;
            }

            & > div {
                display: inline-block;
                margin-left: 20px;
            }

            & .p-rating-icon:hover {
                &.pi-star-fill {
                    color: var(--primary-color) !important;
                }

                &.pi-star {
                    color: var(--text-color) !important;
                }
            }
        }
    }

    &:after {
        content: " ";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0;
    }
}

.documents {
    width: 100%;
    margin: 150px auto;

    &-headline {
        width: 100%;
        text-align: center;
        font-size: 1.6em;
        margin-bottom: 75px;
    }

    &-content {
        display: flex !important;
        flex-wrap: wrap;
        justify-content: center;
        margin: auto;
        width: 90%;
        max-width: 1170px;

        &-card {
            max-width: 100%;
            width: 320px;
            margin: 10px;
            background-color: var(--surface-d) !important;
            page-break-inside: avoid;
        
            & .p-card-title {
                font-size: 1.35rem;
            }
        }
    }
}

.experience {
    margin: 150px auto;
    width: 90%;
    max-width: 1170px;

    &-headline {
        width: 100%;
        text-align: center;
        font-size: 1.6em;
        margin-bottom: 75px;
    }

    & .p-accordion-header-link, & .p-accordion-content {
        background-color: var(--surface-d) !important;
        
        &:focus {
            box-shadow: none !important;
        }
    }

    &-accordion-tab {
        & .p-accordion-content > * {
            margin: 0 25px 1em 25px;
        }
        
        &-tags {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
        }
    }
}

.icon-link span, .icon-link svg {
    vertical-align: middle;
}

.timeline {
    &-element {
        & h3 {
            margin-top: 0;
        }
    }
}